import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Live Nation",
  pageDescription:
    "Discover how the world's largest live entertainment company uses SpotOn's enterprise POS solutions to bring together multiple venues on one platform.",
  title: "Going live with multiple venues on one platform",
  subTitle: `How Live Nation brings together data and hardware for a better fan experience.`,
  businessName: "Live Nation",
  industry: "Enterprise, Sports & Entertainment",
  location: "Beverly Hills, California",
  products: [
    spotonProducts.ENTERPRISE_POS,
    spotonProducts.HANDHELDS,
    spotonProducts.KITCHEN_DISPLAYS,
  ],
  description:
    "As the world’s largest live entertainment company, Live Nation promotes, operates, and manages ticket sales for tens of thousands of events every year. And through their partnership with SpotOn, they're able to unify their data and devices on a single platform and gain access to the latest emerging technologies.",
  quoteData: {
    imgName: "live-nation.png",
    quote: `“SpotOn (formerly Appetize) makes payment faster and easier, allowing people to spend more time enjoying the show they’ve come to see. We are happy to be working with them to help enhance the concert-going experience.”`,
    personName: "Mark Campana",
    personTitle: "Co-President, North America Concerts",
  },
  goal: `With scattered data and device types, Live Nation needed a reliable enterprise POS to consolidate its systems on one platform. They were also looking for concessions, inventory, KDS, and payment solutions to make ordering and paying easier for fans.`,
  solution: `SpotOn helped Live Nation migrate to a single-tenant environment for better security and data management. And along with faster ordering and payment solutions, Live Nation also gained critical Givex, Skidata, and Ticketmaster integrations.`,
  results: {
    title: `The results`,
    stats: [
      { title: `6,400`, description: `fixed and portable devices deployed` },
      {
        title: `6,900`,
        description: `handhelds deployed`,
      },
      {
        title: `110`,
        description: `amphitheaters, clubs, and theaters covered`,
      },
    ],
  },
};

export const relatedSuccessStories = [];
